<template>
  <div>
    <slot
      name="enabled"
      :onEnabledChange="onEnabledChange"
      :value="enabled"
    ></slot>

    <v-select
      outlined
      dense
      :items="selectItems"
      :item-text="optionTextKey"
      :item-value="optionValueKey"
      :item-disabled="itemDisabled"
      :placeholder="$t('unselect')"
      :multiple="multiple"
      clearable
      v-model="filterValue"
    >
    </v-select>
  </div>
</template>

<script lang="babel" type="text/babel">
import filter from '@/components/list/listSearchAndFilter/filter.js'
export default {
  mixins: [filter],
  computed: {
    itemDisabled() {
      if(typeof this.config.itemDisabled === 'function') {
        return (item) => this.config.itemDisabled(item, window.eagleLodash.cloneDeep(this.listMeta))
      }
      return () => false
    },
    multiple() {
      return this.config.multiple === true
    },
    isValueEmpty() {
      if(!Array.isArray(this.selectItems)) return true
      if(!this.multiple) {
        return this.selectItems.some(option => {
          return option[this.optionValueKey] == this.filterValue
        }) === false
      }

      if(window.eagleLodash.isEmpty(this.filterValue)) return true
      if(!Array.isArray(this.filterValue)) return true
      const selectItemsStringArray = this.selectItems.map(option => option[this.optionValueKey])
      for(const item of this.filterValue) {
        if(selectItemsStringArray.includes(item)) return false
      }
      return true
    },
    optionTextKey() {
      return this.config.optionTextKey || 'text'
    },
    optionValueKey() {
      return this.config.optionValueKey || 'value'
    },
    selectItems() {
      return this.options.map(option => {
        let text = option[this.optionTextKey]
        const value = option[this.optionValueKey]
        if(this.config.optionTextPrefix) {
          text = `${this.config.optionTextPrefix}.${text}`
        }

        if(this.config.optionTextSuffix) {
          text = `${text}.${this.config.optionTextSuffix}`
        }

        if(typeof this.config.optionText === 'function') {
          text = this.config.optionText(option[this.optionTextKey], option)
        }

        let meta = null
        if(typeof this.config.optionMeta === 'function') {
          meta = this.config.optionMeta(option)
        }

        return {
          [this.optionTextKey]: this.$t(text),
          [this.optionValueKey]: value,
          ...meta,
        }
      })
    },
    options() {
      if(typeof this.config.options === 'function') {
        return this.config.options(this.listMeta).map(option => {
          if(typeof option != 'object') {
            return {
              [this.optionTextKey]: option,
              [this.optionValueKey]: option,
            }
          }

          return option
        })
      }

      if(!Array.isArray(this.config.options)) {
        return []
      }

      const firstOption = this.config.options[0]
      if(typeof firstOption != 'object') {
        return this.config.options.map(option => ({
          [this.optionTextKey]: option,
          [this.optionValueKey]: option,
        }))
      }

      return this.config.options
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
